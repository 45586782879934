<template>
  <div class="not-found">
    <h1 class="text-white text-center mt-5">404</h1>
    <h2 class="text-white text-center">Not Found</h2>
  </div>
</template>
<script>
  export default {

  }
</script>
<style lang="scss">
  .not-found {
    font-family: 'Futura PT', sans-serif;

    h1 {
      font-size: 88px;
    }
  }
</style>
